import Vue from "vue"
import Axios from 'axios'
import API from '../../config/development'
import { objToCsv, downloadURL } from '../../utils'
import moment from 'moment'
import APIS from '../../constants/api';


const downloadURI = (uri, target, name) => {
    const link = document.createElement('a')
    if (name) link.download = name
    link.href = uri
    if (target) link.target = target
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
}

const state = {
    campaignReportsData:""
}

const getters = {
    performance_data: state => state.campaignReportsData.performance,
    campaignSummary_data: state => state.campaignReportsData.campaignSummary,
    file_summary: state => state.campaignReportsData.file_Summary,
    filePerfData: state => state.campaignReportsData.filePerfData,
    leads_data: state => state.campaignReportsData.leadsData
}

const mutations = {
    SET_REPORT_DATA:(state, payload)=>{
        let list = []
        for (const key in payload.data.filePerfData) {
            list.push(payload.data.filePerfData[key])
        }
        payload.data.filePerfData = list
        state.campaignReportsData = payload.data
    },
    SET_FILE_SUMMARY_DATA:(state, payload)=>{
        state.campaignReportsData.file_Summary = payload.data;
    }
}

const actions = {
  async saveLeadInfo ({ state }, data) {
    try {
      data.leads_data.leads_updated_time = moment().unix()
      let resp = await this.$axios.post(`${API.Backend_URL}/save-leads-data`, data)
      return resp
    } catch (error) {
      console.log(error)
    }
  },
  async getCampaignReport ({ commit }, data) {
    try {
      const resp = await this.$axios.post(`${API.Backend_URL}/campaign-report-data`, data)
      commit('SET_REPORT_DATA', resp.data)
    } catch (error) {
      console.log(error)
    }
  },
    async downloadFilePeformanceList ({ state }, name) {
        try {
          const data = objToCsv(state.campaignReportsData.filePerfData)
          const fileName = name + '_fileMetrics.csv'
          const blob = new Blob([data], { type: 'text/csv' })
          const url = window.URL.createObjectURL(blob)
          downloadURL(url, '_blank', fileName)
        } catch (error) {
          console.log(error)
        }
    },
    async getDownloadEventData({commit},data){
        // console.log(data);
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/campaign-summary-download`,data)
            const contentType = resp.headers['Content-Type'] || resp.headers['content-type']
            const contentDisposition = resp.headers['Content-Disposition'] || resp.headers['content-disposition']
            const fileName = contentDisposition.substring(contentDisposition.lastIndexOf('=')+1).replaceAll(`"`,'')
            const blob = new Blob([resp.data], { type: contentType })
            const url = window.URL.createObjectURL(blob)
            downloadURI(url, '_blank', fileName)
        } catch (error) {
            console.log(error)
        }
    },
    async getMultiRunIdDownloadEventData({commit},data){
        try {
            const resp = await this.$axios.post(`${API.Backend_URL}/multi-runid-download`,data)
            const contentType = resp.headers['Content-Type'] || resp.headers['content-type']
            const contentDisposition = resp.headers['Content-Disposition'] || resp.headers['content-disposition']
            const fileName = contentDisposition.substring(contentDisposition.lastIndexOf('=')+1).replaceAll(`"`,'')
            const blob = new Blob([resp.data], { type: contentType })
            const url = window.URL.createObjectURL(blob)
            downloadURI(url, '_blank', fileName)
            return data.prop.event
        } catch (error) {
            console.log(error)
            return data.prop.event
        }
    },

    async downloadReportList({commit}, data){ 
      try{
        const resp = await this.$axios.post(`${API.Backend_URL}/report-list-download`,data,{
                          headers : {
                              "authorization":localStorage.getItem('Accesstoken')
                          }
                      })
        const contentType = resp.headers['Content-Type'] || resp.headers['content-type']
        const contentDisposition = resp.headers['Content-Disposition'] || resp.headers['content-disposition']
        const fileName = contentDisposition && contentDisposition.substring(contentDisposition.lastIndexOf('=')+1).replaceAll(`"`,'')
        const blob = new Blob([resp.data], { type: contentType })
        const url = window.URL.createObjectURL(blob)
        downloadURI(url,'_blank',fileName)
        } catch(error){
          console.log(error)
        }
    },

    async getFileData({commit},data){
        console.log(data);
        try{
            const resp = await this.$axios.post(`${API.Backend_URL}/file-data`,data)

            commit('SET_FILE_SUMMARY_DATA',resp.data)
            console.log(resp.data);
        }catch(error){
            console.log(error)
        }
    },
    async saveDlrHeaders ({ state }, data) {
      try {
        let resp = await this.$axios.post(`${API.Backend_URL}/save-dlr-headers`, data)
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async getDlrHeaders ({ state }, data) {
      try {
        let resp = await this.$axios.post(`${API.Backend_URL}/get-dlr-headers`, data)
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async getRecipients ({ state }, data) {
      try {
        let resp = await this.$axios.post(`${API.Backend_URL}/get-recipients`, data)
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async createDlrSchedule ({ state }, data) {
      try {
        let resp = await this.$axios.post(`${API.Backend_URL}${APIS.dlr.createDlrSchedule}`, data)
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async updateDlrSchedule ({ state }, data) {
      try {
        let resp = await this.$axios.post(`${API.Backend_URL}${APIS.dlr.updateDlrSchedule}`, data)
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async updateDlrScheduleStatus ({ state }, data) {
      try {
        let resp = await this.$axios.post(`${API.Backend_URL}${APIS.dlr.updateDlrScheduleStatus}`, data)
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async getAllDlrSchedules ({ state }, data) {
      try {
        let resp = await this.$axios.post(`${API.Backend_URL}${APIS.dlr.getAllDlrSchedules}`, data)
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async getAllDlrExecuted ({ state }, data) {
      try {
        let resp = await this.$axios.post(`${API.Backend_URL}${APIS.dlr.getAllDlrExecuted}`, data)
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async getDlrSchedule ({ state }, data) {
      try {
        let resp = await this.$axios.post(`${API.Backend_URL}${APIS.dlr.getDlrSchedule}`, data)
        return resp;
      } catch (error) {
        console.log(error);
      }
    },
    async downloadDetailedReport({ state }, payload) {
      try {
        const res = await this.$axios.post(`${API.Backend_URL}${APIS.dlr.downloadDetailedReport}`, payload, { responseType: 'blob' });
        return res;
      }
      catch(error) {
        console.log(error);
      }
    },
    async getLatestReportCount({ state }, payload) {
      try {
        const res = await this.$axios.post(`${API.Backend_URL}${APIS.dlr.getLatestReportCount}`, payload);
        return res;
      }
      catch(error) {
        console.log(error);
      }
    },
    async getLatestReportCount({ state }, payload) {
      try {
        const res = await this.$axios.post(`${API.Backend_URL}${APIS.dlr.getLatestReportCount}`, payload);
        return res;
      }
      catch(error) {
        console.log(error);
      }
    }
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}

//getCampaignReportsData