import { nextTick } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';

// const Sidebar = () => import('../components/layout/Sidebar.vue')
// const Navbar = () => import('../components/layout/Navbar.vue')
const Loginpage = () => import('../components/views/account/Login.vue')
const SettingsList = () =>
    import('../components/views/dashboard/settings/Settingslist.vue')
const Addsettings = () =>
    import('../components/views/dashboard/settings/Addsettings.vue')
const Addwhatsappsettings = () =>
    import('../components/views/dashboard/settings/AddWhatsappSettings.vue')
const AddTruecallerSettings = () =>
    import('../components/views/dashboard/settings/AddTruecallerSettings.vue')
const AddRCSSettings = () =>
    import('../components/views/dashboard/settings/AddRCSSettings.vue')
const Createcampaign = () =>
    import('../components/views/dashboard/campaigns/Createcampaign.vue')
const Camplandpage = () =>
    import('../components/views/dashboard/campaigns/Campaignlandingpage.vue')
const Campdashboard = () =>
    import('../components/views/dashboard/campaigns/Campaigndashboard.vue')
const Content = () =>
    import('../components/views/dashboard/campaigns/Content.vue')
const WhatsappContent = () =>
    import(
        '../components/views/dashboard/campaigns/WhatsApp/WhatsappContent.vue'
    )
const WhatsappPreview = () =>
    import(
        '../components/views/dashboard/campaigns/WhatsApp/WhatsappPreview.vue'
    )
const TruecallerContent = () =>
    import(
        '../components/views/dashboard/campaigns/Truecaller/TruecallerContent.vue'
    )
const TruecallerPreview = () =>
    import(
        '../components/views/dashboard/campaigns/Truecaller/TruecallerPreview.vue'
    )
const Preview = () =>
    import('../components/views/dashboard/campaigns/Preview.vue')
const Schedule = () =>
    import('../components/views/dashboard/campaigns/Schedule.vue')
const Run = () => import('../components/views/dashboard/campaigns/Run.vue')
const Schedulelist = () =>
    import('../components/views/dashboard/scheduled/Scheduledlist.vue')
const Contactlist = () =>
    import('../components/views/dashboard/contactlist/Contactlist.vue')
const Baseline = () =>
    import('../components/views/dashboard/baseline/Baseline.vue')
const CreateBaseline = () =>
    import('../components/views/dashboard/baseline/BaselineCreate.vue')
const Reportslist = () =>
    import('../components/views/dashboard/reports/Reportslist.vue')
const LinkDashboard = () =>
    import('../components/views/dashboard/links/LinkDashboard.vue')
const LinkReports = () =>
    import('../components/views/dashboard/links/LinkReports.vue')
const AddLinks = () =>
    import('../components/views/dashboard/links/AddLinks.vue')
const Reports = () =>
    import('../components/views/dashboard/reports/Reportdashboard.vue')
const SignOutPage = () => import('../components/views/account/Signout.vue')
const CreateBlacklist = () =>
    import('../components/views/dashboard/contactlist/CreateBlacklist.vue')
const UserProfile = () =>
    import('../components/views/dashboard/visitorprofile/VisitorProfile.vue')
const RCSContent = () => import('../components/views/dashboard/campaigns/RCS/RCSContent.vue')
const RCSPreview = () => import('../components/views/dashboard/campaigns/RCS/RCSPreview.vue')
const DetailedReportConfig = () => import('../components/views/dashboard/settings/DetailedReportConfig.vue')
const CreateSchedule = () => import('../components/views/dashboard/settings/CreateSchedule.vue')
const CreateWorkflow = () => import('../components/views/dashboard/settings/CreateWorkflow.vue')
const ExportList = () => import('../components/views/dashboard/settings/ExportList.vue')
const ViewApproval = () => import('../components/views/dashboard/settings/ViewApproval.vue')
const Approvals = () => import('../components/views/dashboard/settings/Approvals.vue')
const AuditLog = () => import('../components/views/dashboard/settings/AuditLog.vue')
// const FullScreenLoader = () => import('../components/common/FullScreenLoader.vue')

const routes = [
    {
        path: '/',
        name: 'Loginpage',
        component: Loginpage,
        meta: { noSidebar: true, noAuth: true }
    },
    {
        path: '/Loginpage',
        name: 'Loginpage',
        component: Loginpage,
        meta: { noSidebar: true, noAuth: true }
    },
    {
        path: '/Signout',
        name: 'Signout',
        component: SignOutPage,
        meta: { noSidebar: true, noAuth: true }
    },
    {
        path: '/SettingsList',
        name: 'SettingsList',
        component: SettingsList,
        meta: { title: 'WCM | Settings' }
    },
    {
        path: '/SettingsList/Addsettings/sms',
        name: 'Addsettings',
        component: Addsettings,
        meta: { title: 'WCM | Settings', noSidebar: true }
    },
    {
        path: '/Editsettings/sms',
        name: 'Editsettings',
        component: Addsettings,
        meta: { title: 'WCM | Settings', noSidebar: true }
    },
    {
        path: '/Editsettings/whatsapp',
        name: 'Edit whatsapp setting',
        component: Addwhatsappsettings,
        meta: { title: 'WCM | Whatsapp Settings', noSidebar: true }
    },
    {
        path: '/SettingsList/Addsettings/whatsapp',
        name: 'Addwhatsappsettings',
        component: Addwhatsappsettings,
        meta: { title: 'WCM | Whatsapp Settings', noSidebar: true }
    },
    {
        path: '/Editsettings/truecaller',
        name: 'Edit truecaller setting',
        component: AddTruecallerSettings,
        meta: { title: 'WCM | Truecaller Settings', noSidebar: true }
    },
    {
        path: '/Editsettings/rcs',
        name: 'Edit rcs setting',
        component: AddRCSSettings,
        meta: { title: 'WCM | RCS Settings', noSidebar: true }
    },
    {
        path: '/SettingsList/Addsettings/truecaller',
        name: 'AddTruecallerSettings',
        component: AddTruecallerSettings,
        meta: { title: 'WCM | Truecaller Settings', noSidebar: true }
    },
    {
        path: '/SettingsList/Addsettings/rcs',
        name: 'AddRCSSettings',
        component: AddRCSSettings,
        meta: { title: 'WCM | RCS Settings', noSidebar: true }
    },
    {
        path: '/SettingsList/Reportconfig',
        name: 'DetailedReportConfig',
        component: DetailedReportConfig,
        meta: { title: 'Detailed Standard Reports', noSidebar: true }
    },
    {
        path: '/SettingsList/CreateSchedule',
        name: 'CreateSchedule',
        component: CreateSchedule,
        meta: { title: 'Create Schedule', noSidebar: true }
    },
    {
        path: '/SettingsList/CreateWorkflow',
        name: 'CreateWorkflow',
        component: CreateWorkflow,
        meta: { title: 'Create Workflow', noSidebar: true }
    },
    {
        path: '/SettingsList/ViewApproval',
        name: 'ViewApproval',
        component: ViewApproval,
        meta: { title: 'Create Workflow', noSidebar: true }
    },
    {
        path: '/ExportList',
        name: 'ExportList',
        component: ExportList,
        meta: { title: 'Export List', noSidebar: true }
    },
    {
        path: '/Approvals',
        name: 'Approvals',
        component: Approvals,
        meta: { title: 'Approval List' }
    },
    {
        path: '/Approvals/AuditLog',
        name: 'AuditLog',
        component: AuditLog,
        meta: { title: 'Audit Log' }
    },
    {
        path: '/Createcampaign/Contactlist/:type/:id',
        name: 'Createcampaign_id',
        component: Createcampaign,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Contactlist/:type',
        name: 'Createcampaign',
        component: Createcampaign,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Contacts/CreateBlacklist',
        name: 'CreateBlacklist',
        component: CreateBlacklist,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Camplandpage',
        name: 'Camplandpage',
        component: Camplandpage,
        meta: { title: 'WCM | Campaigns' }
    },
    {
        path: '/Campdashboard/:type',
        name: 'Campdashboard',
        component: Campdashboard,
        meta: { title: 'WCM | Campaigns' }
    },
    {
        path: '/SettingsList/:tab',
        name: 'SettingsList',
        component: SettingsList,
        meta: { title: 'SettingsList' }
    },
    {
        path: '/Createcampaign/Content/sms/:id',
        name: 'Content_id',
        component: Content,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Content/sms',
        name: 'Content',
        component: Content,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Content/whatsapp/:id',
        name: 'WhatsappContent_id',
        component: WhatsappContent,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Content/whatsapp',
        name: 'WhatsappContent',
        component: WhatsappContent,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Content/truecaller/:id',
        name: 'TruecallerContent_id',
        component: TruecallerContent,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Content/truecaller',
        name: 'TruecallerContent',
        component: TruecallerContent,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Preview/whatsapp/:id',
        name: 'WhatsappPreview',
        component: WhatsappPreview,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Preview/sms/:id',
        name: 'Preview',
        component: Preview,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Preview/truecaller/:id',
        name: 'TruecallerPreview',
        component: TruecallerPreview,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Schedule/:id',
        name: 'Schedule',
        component: Schedule,
        meta: { title: 'WCM | Schedules', noSidebar: true }
    },
    {
        path: '/Createcampaign/Run/:type/:id',
        name: 'Run',
        component: Run,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Schedulelist',
        name: 'Schedulelist',
        component: Schedulelist,
        meta: { title: 'WCM | Schedules' }
    },
    {
        path: '/Contacts',
        name: 'Contacts',
        component: Contactlist,
        meta: { title: 'WCM | Contacts' }
    },
    {
        path: '/Baseline',
        name: 'Baseline',
        component: Baseline,
        meta: { title: 'WCM | Baseline' }
    },
    {
        path: '/CreateBaseline/:id',
        name: 'CreateBaseline_id',
        component: CreateBaseline,
        meta: { title: 'WCM | Baseline' }
    },
    {
        path: '/CreateBaseline',
        name: 'CreateBaseline',
        component: CreateBaseline,
        meta: { title: 'WCM | Baseline' }
    },
    {
        path: '/Reportslist',
        name: 'Reportslist',
        component: Reportslist,
        meta: { title: 'WCM | Reports' }
    },
    {
        path: '/Reports/:id/:campName/:channel',
        name: 'Reports',
        component: Reports,
        meta: { title: 'WCM | Reports' }
    },
    {
        path: '/LinkReports/:id',
        name: 'LinkReports',
        component: LinkReports,
        meta: { title: 'WCM | Links' }
    },
    {
        path: '/LinkDashboard',
        name: 'LinkDashboard',
        component: LinkDashboard,
        meta: { title: 'WCM | Links' }
    },
    {
        path: '/Links/AddLinks',
        name: 'AddLinks',
        component: AddLinks,
        meta: { title: 'WCM | Links' }
    },
    {
        path: '/EditLinks',
        name: 'EditLinks',
        component: AddLinks,
        meta: { title: 'WCM | Settings', noSidebar: true }
    },
    {
        path: '/Userprofile',
        name: 'Userprofile',
        component: UserProfile,
        meta: { title: 'WCM | UserProfile' }
    },
    {
        path: '/Createcampaign/Content/rcs/:id',
        name: 'RCSContent_id',
        component: RCSContent,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Content/rcs',
        name: 'RCSContent',
        component: RCSContent,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    },
    {
        path: '/Createcampaign/Preview/rcs/:id',
        name: 'RCSPreview',
        component: RCSPreview,
        meta: { title: 'WCM | Campaigns', noSidebar: true }
    }

    // Error pages should be in bottom
    // { path: '/404', component: PageNotFound, name: '404', meta: { noAuth: true } },
    // { path: '/401', component: InvalidRequest, name: '401', meta: { noAuth: true } },
    // { path: '*', redirect: '/404', meta: { noAuth: true } }
]

const router = new createRouter({
    history: createWebHistory(),
    base: process.env.BASE_URL,
    routes
})

router.beforeEach(async (to, from, next) => {
    if (localStorage.Accesstoken != null) {
        if (to.path == '/Loginpage' || to.path == '/') {
            next('/Campdashboard/sms')
        } else {
            next()
        }
    } else if (to.path != '/Loginpage') {
        next('/Loginpage')
    } else {
        next()
    }
})

const DEFAULT_TITLE = 'WCM | Wisely'
router.afterEach((to, from) => {
    // Use next tick to handle router history correctly
    // ref: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
    nextTick(() => {
        document.title = to.meta.title || DEFAULT_TITLE
    })
})

export default router
