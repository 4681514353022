export default {
    settings: {
        get: {
            all: '/list-settings-data',
            one: '/single-setting-data'
        },
        set: {
            one: '/create-settings',
            delete: '/delete-setting-record'
        }
    },
    contacts: {
        get: {
            all: '/get-contact-list'
        },
        set: {
            one: '',
            delete: '/contact-list-delete'
        }
    },
    campaign: {
        get: {
            all: '',
            storedContacts: '',
            wiselySenderIds: '/connect/',
            getConnectsInfo: '/connect/',
            getCustomConnectsList: '/connect/list/'
        },
        set: {
            one: '/api/setsubaccount',
            createOrUpdate: '/connect/',
            statusChange: '/connect/'
        }
    },
    user: {
        login: '/api/auth/signin',
        register: '/api/auth/signup',
        forgot: 'api/auth/forgotPassword',
        reset: 'api/auth/resetPassword',
        get: {
            one: '/user/'
        },
        dashboard: '/user/',
        toaster: '/user/',
        edgeNodeAccess: '/user/edgenodeaccess/', // as per 2102020 mail // /user/createedgenodeaccess/ as per 2020-06-23 mail
        alreadyLoginFlagUpdate: '/user/'
    },
    auth: {
        loginWisely: '/signin-wisely',
        fetchScopes: '/fetch-scopes'
    },
    dlr: {
        createDlrSchedule: '/create-dlr-schedule ',
        getAllDlrSchedules: '/get-all-dlr-schedules',
        getAllDlrExecuted: '/get-all-dlr-executed',
        updateDlrSchedule: '/update-dlr-schedule',
        updateDlrExecuted: '/update-dlr-executed',
        getDlrSchedule: '/get-dlr-schedule',
        downloadDetailedReport: '/download-detailed-report',
        updateDlrScheduleStatus: '/update-dlr-schedule-status',
        getLatestReportCount: '/latest-completed-dlr-reports'
    },
    approval: {
        createWorkflow: '/settings/workflow/createedit',
        fetchRolesAndUsers: '/settings/workflow/fetch-users',
        approvalSettingsList: '/settings/workflow/workflow-settings-list',
        workflowDetails: '/settings/workflow/workflow-detail',
        changeActive: '/settings/workflow/change-active-state',
        campaignCreators: '/settings/workflow/fetch-creators',
        sendForApproval: '/workflow/send-for-approval',
        getApprovalsList: '/get-approvals-list',
        approveOrReject: '/workflow/review-module',
        getAuditLogs: '/workflow/get-audit-logs'
    }
}
